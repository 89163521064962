import { useLocation } from '@remix-run/react'
import { get as getIn } from 'lodash-es'
import { useEffect, useState } from 'react'
import { type FormContextValue } from 'remix-validated-form'
import { aIsFormLocked, useAtom } from '~/atoms'
import { toast } from '~/components/ui'

export default function useFormHelpers(
  formId: string,
  formContext: FormContextValue
) {
  const location = useLocation()
  const [isFormChanged, setIsChanged] = useState<boolean>(false)
  const [_, setIsFormLocked] = useAtom(aIsFormLocked)

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (
        event.keyCode === 13 &&
        (event.target.nodeName === 'INPUT' ||
          event.target.nodeName === 'SELECT')
      ) {
        // var form = event.target.form
        // var index = Array.prototype.indexOf.call(form, event.target)
        // form.elements[index + 1].focus()
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [])

  useEffect(() => {
    if (Object.keys(formContext.fieldErrors).length > 0) {
      console.error(formId, 'errors', formContext.fieldErrors)
      toast({
        title: 'Form Errors',
        description: 'Please check form for required fields and errors',
        variant: 'destructive',
      })
    }
  }, [formContext.fieldErrors, formId])

  useEffect(() => {
    if (formContext.isSubmitting) {
      return
    }
    const updatedEntries = Object.fromEntries(formContext.getValues().entries())
    // console.info(formId, 'updated values', updatedEntries)

    // Default values get loaded after first render
    if (typeof getIn(formContext.defaultValues!, 'created') === 'undefined') {
      return
    }

    // location, sale and lease spaces _id, modified, created
    const ignoreKeys = [
      'organization[_id]',
      'organization[slug]',
      'organization[name]',
      'slug',
      'modified',
      'offMarketDate', // set to null in prepare listing, but shows as undefined on form
      'activeDate',
      'property.placeId',
      'property.country',
      'location',
    ]
    const changedKeys = Object.keys(updatedEntries).filter((key) => {
      if (
        key.startsWith('_') ||
        ignoreKeys.includes(key) ||
        ignoreKeys.filter((k) => key.startsWith(k)).length > 0
      ) {
        return false
      }
      const value = getIn(formContext.defaultValues!, key, '')
      if (updatedEntries[key] == 'on') {
        return value != true
      }
      if (value === null && updatedEntries[key] === '') {
        return false
      }
      return updatedEntries[key] != value
    })
    const changed = changedKeys.length > 0
    console.info('Form changed', changed, changedKeys)
    if (changed !== isFormChanged) {
      setIsChanged(changed)
      setIsFormLocked(changed)
    }
  }, [formContext, isFormChanged, setIsFormLocked])

  function handleOnBeforeUnload() {
    if (isFormChanged) {
      return 'Are you sure you want to leave this page? Changes you have made will not be saved.'
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (process.env.NODE_ENV === 'development' && import.meta.hot) {
      return
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload)
  }, [isFormChanged])

  useEffect(() => {
    window.removeEventListener('beforeunload', handleOnBeforeUnload)
    setIsFormLocked(false)
  }, [location.pathname])

  return [isFormChanged]
}
